import VueI18n from "vue-i18n";
const availableLangs = ["en", "pt"];

let i18n = null;

export default class LanguagesManager {
  constructor(ctx, languages, userLang) {
    if (!i18n) {
      const currentLang = userLang || LanguagesManager.getCurrentLanguage();

      LanguagesManager.setLanguage(currentLang);

      i18n = new VueI18n({
        locale: currentLang,
        messages: languages,
        silentTranslationWarn: true,
      });

      ctx.i18n = i18n;
    }

    this.i18n = i18n;
  }

  static getCurrentLanguage() {
    const storageLang = localStorage.getItem("currentLang");
    const storageDefault = localStorage.getItem("userDefault")
    const browserLang = (
      navigator.language ||
      navigator.userLanguage ||
      ""
    ).split(/[-_]+/)[0];

    if(!storageDefault) return null
    // The first valid language will return in order of priority
    // Priority order:
    // QueryParam> LocalStorage> Browser> Standard English
    const currentLang = [
      storageLang,
      browserLang,
    ].find((language) => availableLangs.includes(language));

    return currentLang;
  }

  static setLanguage(userLang,defaults=null) {
    if(defaults){
      setTimeout(() => {
      localStorage.setItem("userDefault", userLang); 
    });
    }
    // necessary when opening in anonymous tab for the first time!
    setTimeout(() => {
      localStorage.setItem("currentLang", userLang);
    });
  }

  static getI18nService() {
    if (!i18n) {
      console.error("Zoox: i18n service hasn't been set");
    }

    return i18n;
  }

  static getCurrentCulture() {
    let lang = LanguagesManager.getCurrentLanguage();
    return lang === "pt" ? "pt-BR" : "en-US";
  }

  static getCurrentMoedaCulture() {
    let lang = LanguagesManager.getCurrentLanguage();
    return lang === "pt" ? "R$" : "$";
  }
}
