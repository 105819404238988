// require("dotenv").config();
const { version } = require("../package.json");

require("dotenv").config();

const ENV = process.env.NODE_ENV || "development";
console.log(__dirname)
const envConfig = require( `./environments/${ENV}.js`);

const config = Object.assign(
  {
    [ENV]: true,
    env: ENV,
    name: "data-to-act-front",
    secretKey: process.env.SECRET_KEY || "changeme",
    version,
  },
  envConfig
);

module.exports = config;
