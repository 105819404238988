const path = require("path");

const logPath = path.join(__dirname, "../../logs/dev.log");

module.exports = {
  hostName: process.env.HOST_NAME || "http://localhost:8080",
  web: {
    port: process.env.PORT,
  },
  api: {
    auth: process.env.API_AUTH,
  },
  logging: {
    appenders: {
      console: { type: "console" },
      file: { type: "file", filename: logPath },
    },
    categories: {
      default: { appenders: ["console"], level: "debug" },
    },
  },
  file_storage: {
    publicBucket: process.env.PUBLIC_FILES_BUCKET,
    privateBucket: process.env.PRIVATE_FILES_BUCKET,
    s3_access_key: process.env.S3_ACCESS_KEY,
    s3_secret_key: process.env.S3_SECRET_KEY,
    s3_region: process.env.S3_REGION,
  },
  aws_credentials: {
    access_key: "ASIAYL56HQUUNZBXX2ZQ",
    secret_key: "w1MC3e2zxwOlz+xohIiJL7V17e8gFT2qZLZk1+eL",
    sessionToken: "IQoJb3JpZ2luX2VjENn//////////wEaCXVzLWVhc3QtMSJHMEUCIGLixsSdrqEel2OA/AMAx2KvsKznvlbzwrbX1ncUMwQCAiEA9aq3oNEUGesoPrr1X3BnKRMz86n3S2qiEsaHEpYJhTUqggMIgv//////////ARACGgw1NzUzODc2OTg0NzIiDM9XRfrJN5TgPhrk6SrWAtuPg1X1U6Uj5+fCiVOg+BmaZWMDG5wvXDMixVkacDTQri/P9aAXhg37gskvZG/sV3plXnOAELU63c2RkjBEo6QVqFeug5psataQLPgnzKMfu4Lr4t/eKhK/+rQe9jnBwONKRocRFSx+Y69VYNt/fydFBpWtqgl6uEsUGvL/uQO3xhyptTXlgv5FseGHLjBPnsAsL3PQbOk6Z12g+ZMe8kObRd8JZQOR75w0h+St9afxc9rYDAREhBdcOD2WQ807sqwBSrfD6G/PNLgqNFDmTAwddAICgr1gwgj+L9eb0OvV4StkBP1AxMAp0NxNn7vJfsiKH9eBBdb8XDf7DVSh7sj+ze6kC4KkD8EWnMFMCG+HdclYklmBHq60Qo6OWxI4U/gmnxNxsumTkY72SS7DF/R6ZYKtwRQmlmxk+RLTJgaXVdQnkNy0qNbWeK37qbUOIi/dfK+KuDDQv/qfBjqnAbIviOmr4gVFkDi35+FAj8eRof5V4yAJeIji5DkYIcey2DuYYEONxsV9P/IyjXqO2MLZgAreR0FjYKo3eXfQ+uZ0yQ50zZNRYwwARXTz6e40SPMEKBMk9dIpnKlqzu8N4Xm6X3UJQ0KOWYO+Yy631zXoNNee8o8IArRhEuF5k1J/Osk5HU77LCCmXrKfTupZurE+ORwSjnk8Si02kDLB/PqWfEAABf1c",
  },
  db: {
    host: process.env.POSTGRES_HOST,
    user: process.env.POSTGRES_USER,
    password: process.env.POSTGRES_PASSWORD,
    database: process.env.POSTGRES_DB,
    port: process.env.POSTGRES_PORT,
  },
  redis: {
    host: process.env.REDIS_HOST,
    port: process.env.REDIS_PORT,
    user: process.env.REDIS_USER,
    password: process.env.REDIS_PASSWORD,
    tls: process.env.REDIS_TLS,
  },
};

