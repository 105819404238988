import ApiClient from "@/services/ApiClient";

export default class SampleService {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getAll() {
    return this.apiClient.get(`api/sample`);
  }
}
