import { LanguagesManager } from "@/services";
import Vue from "vue";
import moment from "moment";

/**
 * Brazilian date format
 */
Vue.filter("brDate", (v) => {
  if (v) {
    return moment(v).format("DD/MM/YYYY");
  }
  return "";
});

/**
 * Converts to locale format
 */
Vue.filter("pad", (value, len) => {
  if (!value) return value;
  return value.padStart(len, "0");
});

/**
 * Formats a decimal number
 */
Vue.filter("decimal", (v, decimalPlaces) => {
  if (!v) return v;
  const currentLang = LanguagesManager.getCurrentCulture();
  return parseFloat(Number(v))
    .toFixed(decimalPlaces || 2)
    .toLocaleString(currentLang, { minimumFractionDigits: decimalPlaces || 2 });
});

/**
 * Display file size with unit
 */
Vue.filter("formatFileSize", (filesize) => {
  let value = "";
  if (filesize >= 1000000 && filesize < 1000000000) {
      const fixed = Number(filesize/1000000).toFixed(1);
      value = `${fixed} MB`;
  }
  if (filesize < 1000000) {
      const fixed = Number(filesize/1000).toFixed(1);
      value = `${fixed} KB`;
  }
  if(filesize > 1000000000) {
      const fixed = Number(filesize/1000000000).toFixed(1);
      value = `${fixed} GB`;
  }
  return value;
});

Vue.filter("brDateTime", (v) => {
  if (v) {
    return moment(v).format("DD/MM/YYYY HH:mm");
  }
  return "";
});

Vue.filter("localDateTimeFromUTC", (v) => {
  if (v) {
    const utcDate = moment.utc(v).toDate();
    return moment(utcDate).local().format("DD/MM/YYYY HH:mm");
  }
  return "";
});

Vue.filter("uppercase", (v) => {
  if (v) {
    return v.toUpperCase();
  }
  return "";
});
