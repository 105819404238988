import Vue from "vue";
import VueResource from "vue-resource";

import App from "./App.vue";
import vuetify from "@/plugins/components";
import store from "@/plugins/store";
import i18n from "@/plugins/i18n";
import "./filters";

import router from "@/router/index";

import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import addWorldMap from "./assets/js/brazilMapData";
import exportingInit from "highcharts/modules/exporting";
import Treemap from "highcharts/modules/treemap";
import HighchartsMore from "highcharts/highcharts-more";
import VueClipboard from "vue-clipboard2";
// import VueSocketIO from "vue-socket.io";
// import io from "socket.io-client";
import VueApexCharts from "vue-apexcharts";
import Toast from "vue-toastification";
import FlowChart from 'flowchart-vue';
import "vue-toastification/dist/index.css";
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

HighchartsMore(Highcharts);
Treemap(Highcharts);
mapInit(Highcharts);
addWorldMap(Highcharts);
exportingInit(Highcharts);
Vue.use(VueResource);
Vue.use(VueClipboard);
Vue.use(VueApexCharts);
Vue.use(FlowChart);
Vue.use('RecycleScroller',VueVirtualScroller.RecycleScroller)

Vue.component("apexchart", VueApexCharts);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

//load Global Css
import "@/assets/css/style.css";
import "@/assets/scss/animations.scss";
import "@/assets/scss/components.scss";
import "@/assets/scss/main.scss";
Vue.$globalEvent = new Vue();

const aws = require('aws-sdk')

aws.config.update({
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
})

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted() {
    Vue.$globalEvent.$on("httpError", (err) => {
      if (err && err.showException) {
        if (err.status === 0 && !err.body) {
          const msg = i18n.t(`API_ERROR.NETWORK_ERROR`);
          store.dispatch("app/setSnack", {
            color: "error",
            msg,
          });
        }
        if (err.status === 500) {
          const msg = i18n.t(`API_ERROR.DEFAULT`);
          store.dispatch("app/setSnack", {
            color: "error",
            msg,
          });
        }
        if (
          err.body &&
          err.body.detail &&
          typeof err.body.detail === "string" &&
          err.status !== 500
        ) {
          const translated = i18n.t(`API_ERROR.${err.body.detail}`);
          const msg =
            translated.indexOf(err.body.detail) > -1
              ? err.body.detail
              : translated;
          store.dispatch("app/setSnack", {
            color: "error",
            msg,
          });
        }
      }
    });
  },
}).$mount("#app");
