export default Object.assign(
  {},
  require("./global.json"),
  require("./global-error.json"),
  require("./sample.json"),
  require("./login.json"),
  require("./home.json"),
  require("./campaign.json"),
  require("./places.json"),
  require("./profile.json"),
  require("./campaign-report.json"),
  require("./company-filter.json"),
  require("./business-finder.json"),
  require("./company-charts.json"),
  require("./company-table.json"),
  require("./customer-journey.json"),
  require("./market-overview.json"),
  require("./user-dashboard.json"),
  require("./admin-profile.json"),
  require("./person-finder.json"),
  require("./user-details.json"),
  require("./add-enrichment-data.json"),
  require("./api-documentation.json"),
  require("./menu-option.json"),
  require("./data-migration.json"),
  require("./journey-campaign.json"),
  require("./campaign-audiences.json"),
  require("./campaign-content.json"),
  require("./email-template.json"),
  require("./register-audience.json"),
  require("./cellphone-component.json"),
  require("./journey-metrics.json"),
  require("./campaigns-analysis.json"),
  require("./settings-service.json"),
  require("./line-filter.json"),
  require("./attractiveness-index.json"),
  require("./store-performance.json"),
  require("./map-content.json"),
  require("./quicksight-admin.json"),
  require("./variable-mapping.json"),
  require("./backoffice.json"),
  require("./dashboard-settings.json"),
  require("./api-keys.json"),
  require("./notification.json"),
  require("./analysis-shopping.json"),
  require("./sms-template.json"),
  require("./push-template.json"),
  require("./details-client.json"),
  require("./api-consumption.json"),
  require("./term.json"),
  require("./data-extensions.json"),
  require("./months.json"),
  require("./shared-global.json"),
  require("./international_qualify.json"),
  require("./usa-variables.json"),
  require("./search-filter-usa.json"),
  require("./usa-poi.json"),
  require("./api-marketplace.json"),
  require("./api-score.json"),
  require("./places-dashboard.json"),
  require("./ai-monitoring.json"),
  require("./api-error.json"),
  require("./ml-ops.json"),
  require("./usage-report.json"),
  require("./notebooks.json"),
  require("./store-expansion.json"),
  require("./sorrounding-analysis.json"),
  require("./data-prep.json"),
  require("./template-churn.json"),
  require("./recommendations-template.json"),
  require("./forecast-model.json"),
  require("./market-overview-table.json"),
  require("./company-file.json"),
  require("./eyesight.json")
);
