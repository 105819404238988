import Vue from "vue";
import Router from "vue-router";
import ApiClient from "@/services/ApiClient";
// import isUserHasModuleAccess from "@/router/middleware/isUserHasModuleAccess";
// import { UserService, AnalyticsService, AuthService } from "@/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/optout",
      component: () => import("@/components/Pages/Optout/index.vue"),
      name: "outoutPageIndex",
      meta: {
        requiresAuth: false,
        title: "Home",
        breadcrumb: [
          {
            text: "GLOBAL.MENU_OPTIONS.HOME",
            path: "/",
          },
        ],
      },
    },
    {
      path: "/",
      component: () => import("@/components/Layout/Default.vue"),
      children: [
        {
          path: "",
          component: () => import("@/views/HomeTemplate.vue"),
          name: "homePageIndex",
          meta: {
            requiresAuth: true,
            title: "Home",
            breadcrumb: [
              {
                text: "GLOBAL.MENU_OPTIONS.HOME",
                path: "/",
              },
            ],
          },
        },
        {
          path: "database-interaction",
          component: () => import("@/views/index.vue"),

          children: [
            {
              path: "",
              name: "databaseInteractionPage",
              component: () =>
                import("@/components/Pages/DatabaseInteraction/index.vue"),
            },
          ],
          meta: {
            requiresAuth: true,
            title: "Home",
            breadcrumb: [
              {
                text: "GLOBAL.MENU_OPTIONS.HOME",
                path: "/",
              },
              {
                text: "Integração com banco de dados",
                path: "/database-interaction",
              },
            ],
          },
        },
        {
          path: "interactivity-report",
          component: () => import("@/views/InteractivityReportTemplate.vue"),
          name: "interactivityReportPage",
          children: [
            {
              path: "",
              name: "interactivityReportIndexPage",
              component: () =>
                import("@/components/Pages/InteractivityReport/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Relatório de interatividade",
                    path: "/interactivity-report",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "optoutList",
          component: () => import("@/views/JourneyTemplate.vue"),
          name: "optoutList",
          children: [
            {
              path: "/optout/list",
              name: "optOutListPage",
              component: () => import("@/components/Pages/Optout/list.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Optout",
                    path: "/optout/list",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "credentials",
          component: () => import("@/views/CredentialsTemplate.vue"),
          name: "credentialsPage",
          children: [
            {
              path: "",
              name: "credentialsPageIndex",
              component: () =>
                import("@/components/Pages/Credentials/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Credenciais",
                    path: "/credentials",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "conversion-data",
          name: "conversionData",
          component: () => import("@/views/ConversionDataTemplate.vue"),
          children: [
            {
              path: "",
              name: "conversionDataIndexPage",
              component: () =>
                import("@/components/Pages/ConversionData/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados de Conversão",
                    path: "/",
                  },
                ],
              },
            },
            {
              path: "newConnection",
              name: "createConversionConnectionPage",
              component: () =>
                import("@/components/Pages/ConversionData/create.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados de Conversão",
                    path: "/conversion-data",
                  },
                  {
                    text: "Nova Conexão",
                    path: "/conversion-data/newConnection",
                  },
                ],
              },
            },
            {
              path: "edit/:id",
              name: "editConversionDataPage",
              component: () =>
                import("@/components/Pages/ConversionData/edit.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados de Conversão",
                    path: "/conversion-data",
                  },
                  {
                    text: "Editar Conexão",
                    path: "/conversion-data/edit",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "additional-data",
          name: "additionalData",
          component: () => import("@/views/AdditionalDataTemplate.vue"),
          children: [
            {
              path: "",
              name: "additionalDataIndexPage",
              component: () =>
                import("@/components/Pages/AdditionalData/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados Adicionais",
                    path: "/additional-data",
                  },
                ],
              },
            },
            {
              path: "newConnection",
              name: "createAdditionalConnectionPage",
              component: () =>
                import("@/components/Pages/AdditionalData/create.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados Adicionais",
                    path: "/additional-data",
                  },
                  {
                    text: "Nova conexão",
                    path: "/additional-data/newConnection",
                  },
                ],
              },
            },
            {
              path: "edit/:id",
              name: "editAdditionalDataPage",
              component: () =>
                import("@/components/Pages/AdditionalData/editv2.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados Adicionais",
                    path: "/additional-data",
                  },
                  {
                    text: "Editar conexão",
                    path: "/additional-data/edit",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "primary-data",
          name: "primaryData",
          component: () => import("@/views/PrimaryDataTemplate.vue"),
          children: [
            {
              path: "",
              name: "primaryDataIndexPage",
              component: () =>
                import("@/components/Pages/PrimaryData/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados Primários",
                    path: "/primary-data",
                  },
                ],
              },
            },
            {
              path: "newConnection",
              name: "createPrimaryConnectionPage",
              component: () =>
                import("@/components/Pages/PrimaryData/create.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados Primários",
                    path: "/primary-data",
                  },
                  {
                    text: "Nova conexão",
                    path: "/primary-data/newConnection",
                  },
                ],
              },
            },
            {
              path: "edit/:id",
              name: "editPrimaryConnectionPage",
              component: () =>
                import("@/components/Pages/PrimaryData/editv2.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Dados Primários",
                    path: "/primary-data",
                  },
                  {
                    text: "Editar conexão",
                    path: "/primary-data/edit",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "docs",
          name: "docs",
          component: () => import("@/views/DocsTemplate.vue"),
          children: [
            {
              path: ":page/:id",
              name: "docsIndexPage",
              component: () => import("@/components/Pages/Docs/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "",
                    path: "/",
                  },
                  {
                    text: "Documentação",
                    path: "/docs",
                  },
                ],
              },
            },
            {
              path: "apilog/:page/:id",
              name: "logsIndexPage",
              component: () => import("@/components/Pages/Docs/log.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "",
                    path: "/",
                  },
                  {
                    text: "Log",
                    // path: "/docs",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "detailUser",
          name: "detailUser",
          component: () => import("@/views/DocsTemplate.vue"),
          children: [
            {
              path: "",
              name: "detailUserIndexPage",
              component: () =>
                import("@/components/Pages/detailUser/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "",
                    path: "/",
                  },
                  {
                    text: "Documentação",
                    path: "/docs",
                  },
                ],
              },
            },
          ],
        },
        // {
        //   path: "tags",
        //   name: "tags",
        //   component: () => import("@/views/DocsTemplate.vue"),
        //   children: [
        //     {
        //       path: "/",
        //       name: "tagsIndexPage",
        //       component: () => import("@/components/Pages/Docs/tags.vue"),
        //       meta: {
        //         requiresAuth: true,
        //         breadcrumb: [
        //           {
        //             text: "GLOBAL.MENU_OPTIONS.HOME",
        //             path: "/",
        //           },
        //           {
        //             text: "",
        //             path: "/",
        //           },
        //           {
        //             text: "Documentação",
        //             path: "/tags",
        //           },
        //         ],
        //       },
        //     },
        //     {
        //       path: "apilog/:page/:id",
        //       name: "logsIndexPage",
        //       component: () => import("@/components/Pages/Docs/log.vue"),
        //       meta: {
        //         requiresAuth: true,
        //         breadcrumb: [
        //           {
        //             text: "GLOBAL.MENU_OPTIONS.HOME",
        //             path: "/",
        //           },
        //           {
        //             text: "",
        //             path: "/",
        //           },
        //           {
        //             text: "Log",
        //             // path: "/docs",
        //           },
        //         ],
        //       },
        //     },
        //     // {
        //     //   path: "newConnection",
        //     //   name: "createPrimaryConnectionPage",
        //     //   component: () =>
        //     //     import("@/components/Pages/PrimaryData/audience.vue"),
        //     //   meta: {
        //     //     breadcrumb: [
        //     //       {
        //     //         text: "GLOBAL.MENU_OPTIONS.HOME",
        //     //         path: "/",
        //     //       },
        //     //       {
        //     //         text: "Dados primários",
        //     //         path: "/primary-data",
        //     //       },
        //     //       {
        //     //         text: "Nova conexão",
        //     //         path: "/primary-data/newConnection",
        //     //       },
        //     //     ],
        //     //   },
        //     // },
        //   ],
        // },
        {
          path: "dataExtraction",
          name: "dataExtraction",
          component: () => import("@/views/DocsTemplate.vue"),
          children: [
            {
              path: "company",
              name: "dataExtractionPage",
              component: () =>
                import("@/components/Pages/DataExtraction/company.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Extração de dados",
                    path: "/dataExtraction",
                  },
                ],
              },
            },
            {
              path: ":id",
              name: "dataExtractionPage",
              component: () =>
                import("@/components/Pages/DataExtraction/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Detalhes da jornada",
                    path: "/reports/detail",
                  },
                  {
                    text: "Extração de dados",
                    path: "/dataExtraction",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "audience",
          name: "audience",
          component: () => import("@/views/AudienceTemplate.vue"),
          children: [
            {
              path: "",
              name: "audienceIndexPage",
              // component: () => import("@/components/Pages/Audience/index.vue"),
              component: () =>
                import("@/components/Pages/Audience/createdAudiences.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Audiências",
                    path: "/audience",
                  },
                ],
              },
            },
            // {
            //   path: "filtered",
            //   name: "filteredAudienceIndexPage",
            //     component: () =>
            //     import("@/components/Pages/Audience/createdFilteredAudiences.vue"),
            //   meta: {
            //     breadcrumb: [
            //       {
            //         text: "GLOBAL.MENU_OPTIONS.HOME",
            //         path: "/",
            //       },
            //       {
            //         text: "Audiências filtradas",
            //         path: "/audience/filtered",
            //       },
            //     ],
            //   },
            // },
            {
              path: "filter",
              name: "filterAudiencePage",
              component: () => import("@/components/Pages/Audience/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Audiência",
                    path: "/audience",
                  },
                  {
                    text: "Filtro",
                    path: "/audience/filter",
                  },
                ],
              },
            },
            {
              path: "add",
              name: "addAudiencePage",
              component: () => import("@/components/Pages/Filter/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Audiência",
                    path: "/audience",
                  },
                  {
                    text: "Adicionar audiência",
                    path: "/audience/add",
                  },
                ],
              },
            },
            {
              path: "edit/:type/:id",
              name: "editAudiencePage",
              component: () => import("@/components/Pages/Filter/edit.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Audiência",
                    path: "/audience",
                  },
                  {
                    text: "Editar audiência",
                    path: "/audience/edit",
                  },
                ],
              },
            },
            {
              path: "add-filtered",
              name: "addFilteredAudiencePage",
              component: () =>
                import("@/components/Pages/Filter/filteredAudiences.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Audiência",
                    path: "/audience",
                  },
                  {
                    text: "Adicionar audiência filtrada",
                    path: "/audience/add-filtered",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "templates",
          name: "templatesPage",
          component: () => import("@/views/TemplatesConfig.vue"),
          children: [
            {
              path: "",
              name: "templates",
              component: () => import("@/components/Pages/Templates/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Templates criados",
                    path: "/templates",
                  },
                ],
              },
            },
            {
              path: "sms",
              name: "create_sms_templates",
              component: () =>
                import("@/components/Pages/Templates/SMS/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Templates criados",
                    path: "/templates",
                  },
                  {
                    text: "Template SMS",
                    path: "/templates",
                  },
                ],
              },
            },
            {
              path: "push",
              name: "create_push_templates",
              component: () =>
                import("@/components/Pages/Templates/Push/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Templates criados",
                    path: "/templates",
                  },
                  {
                    text: "Template Push",
                    path: "/templates",
                  },
                ],
              },
            },
            {
              path: "email/new",
              name: "create_email_templates",
              component: () =>
                import("@/components/Pages/Templates/Email/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Templates criados",
                    path: "/templates",
                  },
                  {
                    text: "Template Email",
                    path: "/templates",
                  },
                ],
              },
              props: true,
            },
            {
              path: "whatsapp",
              name: "create_whatsapp_templates",
              component: () =>
                import("@/components/Pages/Templates/WhatsApp/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Templates criados",
                    path: "/templates",
                  },
                  {
                    text: "Template WhatsApp",
                    path: "/whatsapp",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "usage",
          name: "usagePage",
          component: () => import("@/views/JourneyTemplate.vue"),
          children: [
            {
              path: "",
              name: "reportsIndex",
              component: () => import("@/components/Pages/Usage/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Uso de Creditos",
                    path: "/usage",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "reports",
          name: "reportsPage",
          component: () => import("@/views/JourneyTemplate.vue"),
          children: [
            {
              path: "",
              name: "reportsIndex",
              component: () => import("@/components/Pages/Reports/reports.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Relatório Geral",
                    path: "/reports",
                  },
                ],
              },
            },
            {
              path: "journey-detail/:id",
              name: "journeyDetailPage",
              component: () => import("@/components/Pages/Journey/detail.vue"),
              props: true,
              meta: {
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Relatório Geral",
                    path: "/reports",
                  },
                  {
                    text: "Detalhes da jornada",
                    path: "/reports/detail",
                  },
                ],
              },
            },
            {
              path: "journey-detail-mock/:id",
              name: "journeyDetailMockPage",
              component: () =>
                import("@/components/Pages/Journey/detail-mock.vue"),
              props: true,
              meta: {
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Relatório Geral",
                    path: "/reports",
                  },
                  {
                    text: "Detalhes da jornada (mock)",
                    path: "/reports/detail",
                  },
                ],
              },
            },
            {
              path: "journey-reports-fails-details/:id",
              name: "journeyReportsFailsDetailsPage",
              component: () =>
                import("@/components/Pages/Journey/reportsFails.vue"),
              props: true,
              meta: {
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Relatório Geral",
                    path: "/reports",
                  },
                  {
                    text: "Detalhes da jornada",
                    path: "/reports/detail",
                  },
                  {
                    text: "Registro de falhas de envio",
                    path: "/reports/journey-reports-fails-details",
                  },
                ],
              },
            },
          ],
        },
        {
          path: "journey",
          name: "journeyPage",
          component: () => import("@/views/JourneyTemplate.vue"),
          children: [
            {
              path: "",
              name: "journeyIndex",
              component: () => import("@/components/Pages/Journey/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Jornadas criadas",
                    path: "/journey/created",
                  },
                  {
                    text: "Criar jornada",
                    path: "/journey",
                  },
                ],
              },
            },
            {
              path: "docs/:id",
              name: "journeyDocsPage",
              component: () => import("@/components/Pages/Journey/docs.vue"),
              props: true,
              meta: {
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                ],
              },
            },
            {
              path: "edit/:id",
              name: "journeyEditPage",
              component: () => import("@/components/Pages/Journey/edit.vue"),
              props: true,
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Jornadas criadas",
                    path: "/journey/created",
                  },
                  {
                    text: "Atualizar jornada",
                    path: "/journey/edit",
                  },
                ],
              },
            },
            {
              path: ":id/reports",
              name: "journeyReportPage",
              component: () => import("@/components/Pages/Journey/reports.vue"),
              props: true,
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Relatórios da jornada",
                    path: "/journey/reports",
                  },
                ],
              },
            },
            {
              path: "created",
              name: "createdJourneysPage",
              component: () =>
                import("@/components/Pages/Journey/createdJourneys.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Jornadas criadas",
                    path: "/journey/created",
                  },
                ],
              },
            },
            // {
            //   path: "detail/:id",
            //   name: "journeyDetailPage",
            //   component: () => import("@/components/Pages/Journey/detail.vue"),
            //   props: true,
            //   meta: {
            //     breadcrumb: [
            //       {
            //         text: "GLOBAL.MENU_OPTIONS.HOME",
            //         path: "/",
            //       },
            //       {
            //         text: "Jornadas criadas",
            //         path: "/journey/created",
            //       },
            //       {
            //         text: "Detalhes da jornada",
            //         path: "/journey/detail",
            //       },
            //     ],
            //   },
            // },
          ],
        },
        {
          path: "document-upload",
          component: () => import("@/views/indexTemplate.vue"),
          children: [
            {
              path: "",
              name: "documentUploadPage",
              component: () =>
                import("@/components/Pages/DocumentUpload/index.vue"),
              meta: {
                requiresAuth: true,
                breadcrumb: [
                  {
                    text: "GLOBAL.MENU_OPTIONS.HOME",
                    path: "/",
                  },
                  {
                    text: "Upload de documentos",
                    path: "/document-upload",
                  },
                ],
              },
            },
          ],
        },
        // {
        //   path: "filter",
        //   name: "filterPage",
        //   component: () => import("@/views/FilterTemplate.vue"),
        //   children: [
        //     {
        //       path: "",
        //       name: "filterIndexPage",
        //       component: () => import("@/components/Pages/Filter/index.vue"),
        //       meta: {
        //         breadcrumb: [
        //           {
        //             text: "GLOBAL.MENU_OPTIONS.HOME",
        //             path: "/",
        //           },
        //           {
        //             text: "Audiências filtradas",
        //             path: "/filter",
        //           },
        //         ],
        //       },
        //     },
        //   ],
        // },
        {
          path: "/sample",
          name: "Sample",
          meta: {
            requiresAuth: true,
            title: "Sample",
            permission: "sample",
            // module: "main",
            breadcrumb: [
              {
                text: "GLOBAL.MENU_OPTIONS.HOME",
                path: "/",
              },
              {
                text: "Sample",
              },
            ],
          },
          component: () => import("@/components/Pages/Sample/index.vue"),
        },
      ],
    },
  ],
});

router.afterEach((to) => {
  if (to.params.page)
    switch (to.params.page.toLowerCase()) {
      case "primarydata":
        to.meta.breadcrumb[1].text = "Dados Primários";
        to.meta.breadcrumb[1].path = "/primary-data";
        break;
      case "additionaldata":
        to.meta.breadcrumb[1].text = "Dados Adicionais";
        to.meta.breadcrumb[1].path = "/additional-data";
        break;
      case "conversiondata":
        to.meta.breadcrumb[1].text = "Dados de Conversão";
        to.meta.breadcrumb[1].path = "/conversion-data";
        break;
    }
});

function promtPass() {
  let pass = window.prompt("Insira a senha", "");
  if (pass !== "dta123!") {
    promtPass();
  } else {
    localStorage.setItem("loggedIn", true);
    return true;
  }
}

async function getZooxEyeInfo(query) {
  if (query["zooxeye"]) localStorage.setItem("zooxEye", query["zooxeye"]);

  let currentToken = localStorage.getItem("zooxeye-token");
  if (
    query["zooxeye-token"] &&
    (!currentToken || currentToken !== query["zooxeye-token"])
  ) {
    try {
      let apiClient = new ApiClient();
      localStorage.setItem("zooxeye-token", query["zooxeye-token"]);

      const URL = `authorization/getUserData`;
      const response = await apiClient.get(URL);
      console.log(response);

      localStorage.setItem("zooxeye-user", JSON.stringify(response));
    } catch (exception) {
      console.log(exception);
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.query && (to.query["zooxeye-token"] || to.query["zooxeye"]))
    await getZooxEyeInfo(to.query);

  const loggedIn =
    localStorage.getItem("loggedIn") || localStorage.getItem("zooxeye-token");

  if (!loggedIn && to.meta.requiresAuth) {
    let pass = promtPass();
    if (pass) next();
  }

  next();
});

export default router;
