const _ = require("lodash");

import { getDefaultState } from "./state";
import { getMenuOptions } from "./menuOptions";

export const setCompany = (state, company) => {
  state.currentCompany = company;
};
export const setUser = (state, data) => {
  state.appUser = data;
};
export const reset = (state) => {
  Object.assign(state, getDefaultState());
};

export const refreshMenuOptions = (state) => {
  state.menuOptions = getMenuOptions();
};

export const setCities = (state, data) => {
  state.cities = [...data];
};

export const setNeighbourhoods = (state, data) => {
  state.neighbourhoods = [...data];
};

export const setStates = (state, data) => {
  state.states = [...data];
};

export const hideSnack = (state) => {
  state.snack.show = false;
};

export const SET_LANGUAGE = (state, data) => {
  state.globalLocale = data;
};

export const SET_DRAWER = (state, payload) => {
  state.drawer = payload;
};
export const setSnack = (state, snack) => {
  state.snack = {
    ...state.snack,
    msg: snack.msg,
    color: snack.color || "info",
    show: true,
  };
};

export const setSnacks = (state, snack) => {
  state.snacks = state.snacks.concat({
    ...state.snack,
    msg: snack.msg,
    color: snack.color,
    show: true,
  });
};

export const START_LOADING = (state, payload) => {
  if (payload.showLoading) {
    state.loading = state.loading + 1;
  }
};

export const FINISH_LOADING = (state, payload) => {
  if (payload.showLoading) {
    state.loading = state.loading - 1;
  }
};

export const SET_LOADING = (state, payload) => {
  if (payload) {
    state.loading = state.loading + 1;
  } else {
    state.loading = state.loading - 1;
  }
};

export const SET_CURRENT_AUDIENCE_INFO = (state, payload) => {
  if (payload) {
    state.currentAudienceInfo = payload;
  }
};

export const CREATE_BASIC_JOURNEY = (state, payload) => {
  if (payload) {
    state.defaultNodeToCreate.isToCreate = true;
    state.defaultNodeToCreate.node = payload;
  }
};

export const RESET_BASIC_JOURNEY = (state) => {
  state.defaultNodeToCreate.isToCreate = false;
  state.defaultNodeToCreate.node = null;
};

export const RESET_CURRENT_AUDIENCE_INFO = (state) => {
  state.currentAudienceInfo = null;
};

export const INSERT_CHIP_ON_LIST = (state, payload) => {
  const is_array = _.isArray(payload);

  if (is_array) {
    // encontrar os novos chips que estão chegando, que não estão no estado ainda
    const ELEMENTS_NOT_IN_STATE = payload.filter((a) => {
      return !state.chipsFilterList.some((b) => b.id === a.id);
    });

    // adicionar elementos novos ao estado
    ELEMENTS_NOT_IN_STATE.forEach((newChip) => {
      state.chipsFilterList.push(newChip);
    });
  } else {
    if (!state.chipsFilterLis.find((e) => e.id == payload.id))
      state.chipsFilterList.push(payload);
  }
};

export const SET_CHIP_TO_EDIT = (state, payload) => {
  state.currentEditChip = payload;
};

export const CREATE_DEFAULT_CHIP = (state, payload) => {
  const LIST = [];

  const id_father = (Math.random() + 1).toString(36).substring(7);
  const id_child = (Math.random() + 1).toString(36).substring(7);
  const dates = payload.textDate.split("até");

  let CHILD = {
    id: id_father,
    childs: [
      {
        id: id_child,
        chip_info_child_id: 7,
        chip_child_label: payload.name,
        chip_father_id: id_father,
        chip_assistant: "",
        chip_text_date: payload.textDate,
        chip_start_date: dates[0].trim() || "",
        chip_end_date: dates[1].trim() || "",
      },
    ],
    chip_computed_labels: payload.name,
    chip_type: "interval",
    chip_title: "Intervalo de tempo",
  };

  LIST.push(CHILD);

  state.chipsFilterList = LIST;
};

export const RESET_CHIP_TO_EDIT = (state) => {
  state.currentEditChip = null;
};

export const REMOVE_FILTER_CHIP_ITEM = (state, payload) => {
  state.chipsFilterList = state.chipsFilterList.filter(
    (chip) => chip.id !== payload.id
  );
};

export const UPDATE_CHIP = (state, payload) => {
  const FOUNDED_CHIP_INDEX = state.chipsFilterList.findIndex(
    (d) => d.id == payload.id
  );

  if (FOUNDED_CHIP_INDEX >= 0)
    state.chipsFilterList[FOUNDED_CHIP_INDEX] = payload;

  // forçar a renderização novamente, visto que um item pode ser
  // um child interno, logo o componente não renderiza com as informaçõe snovas
  const bkp = state.chipsFilterList;
  state.chipsFilterList = [];
  state.chipsFilterList = bkp;
};

export const REMOVE_CHILD_CHIP_ON_FATHER = (state, payload) => {
  const FATHER_IDX = state.chipsFilterList.findIndex(
    (e) => e.id == payload.chip_father_id
  );

  if (FATHER_IDX >= 0) {
    // remover o elemento do pai (payload selecionado)
    state.chipsFilterList[FATHER_IDX].childs = state.chipsFilterList[
      FATHER_IDX
    ].childs.filter((child) => child.id != payload.id);

    // atualizar o computed_label do pai visto que agora ele terá um filho a menos
    state.chipsFilterList[FATHER_IDX].chip_computed_labels =
      state.chipsFilterList[FATHER_IDX].childs
        .map((d) => d.chip_child_label)
        .join("; ");

    // caso não tenha mais filhos, apagar o chip
    if (!state.chipsFilterList[FATHER_IDX].childs.length) {
      state.chipsFilterList = state.chipsFilterList.filter(
        (chip) => chip.id !== state.chipsFilterList[FATHER_IDX].id
      );
    }
  }

  state.currentEditChip = state.chipsFilterList[FATHER_IDX];
};

export const RESET_FILTER_CHIP_LIST = (state) => (state.chipsFilterList = []);
