import Vue from "vue";
import VueI18n from "vue-i18n";
import { LanguagesManager } from "@/services";
import Languages from "@/languages";

Vue.use(VueI18n);

// const storageLang = localStorage.getItem("currentLang");
const storageLang = 'pt';
const browserLang = (navigator.language || navigator.userLanguage || "").split(
  /[-_]+/
)[0];

const availableLangs = Object.keys(Languages);

// The first valid language will return in order of priority
// Priority order:
// QueryParam> LocalStorage> Browser> Standard English
let currentLang = [storageLang, browserLang].find((language) =>
  availableLangs.includes(language)
);

localStorage.setItem("currentLang", currentLang);

const languagesManager = new LanguagesManager(Vue, Languages, currentLang);

export default languagesManager.i18n;
