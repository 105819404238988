import Vue from "vue";
import Vuetify from "vuetify";
import en from "vuetify/lib/locale/en";
import pt from "vuetify/lib/locale/pt";

import "@mdi/font/css/materialdesignicons.css";
import ZooxCharts from "@zoox-ui/charts";
import "@zoox-ui/charts/dist/zoox-ui-charts.css";
import ZooxComponents, { Theme, ZooxIcons } from "@zoox-ui/components";
import "@zoox-ui/components/dist/zoox-design-system.css";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);
Vue.use(ZooxComponents);
Vue.use(ZooxCharts);

export default new Vuetify({
  lang: {
    locales: { pt, en },
    current: "pt",
  },
  theme: {
    Theme,
    themes: {
      light: {
        "light-orange": "#F5A62E",
        "z-light-green": "#239465",
        "z-light-blue": "#51BBE8",
      },
    },
  },
  icons: {
    values: ZooxIcons,
  },
});
